import { render, staticRenderFns } from "./BlogHighlights.vue?vue&type=template&id=4bac14da"
import script from "./BlogHighlights.vue?vue&type=script&lang=ts"
export * from "./BlogHighlights.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlogHighlightTile: require('/src/nuxt-app/components/blog/BlogHighlightTile.vue').default})
