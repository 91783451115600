
import { defineComponent, onMounted } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'VideobotSlider',
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    dataWidgetId: {
      type: String,
      required: false,
      default: null
    }
  },
  setup () {
    onMounted(() => {
      window.Videobot.reload()
    })
  }
})
