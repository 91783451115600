
import Vue from 'vue'
import VekeCarouselArrows from '~/components/veke-carousel/VekeCarouselArrows.vue'
import { UiGetters } from '~/store/ui'

export default Vue.extend({
  name: 'VekeCarousel',
  components: { VekeCarouselArrows },
  props: {
    overlayArrows: {
      type: Boolean,
      default: false
    },
    scrollPerPage: {
      type: Boolean,
      default: true
    },
    perPage: {
      type: Number,
      default: 2
    },
    perPageCustom: {
      type: Array,
      default: () => []
    },
    navigationEnabled: {
      type: Boolean,
      default: true
    },
    navigateTo: {
      type: [Number, Array],
      default: 0
    },
    centerMode: {
      type: Boolean,
      default: false
    },
    navigationClasses: {
      type: [String, Array],
      default: ''
    },
    enableGtmTracking: {
      type: Boolean,
      default: true
    }
  },
  // @ts-ignore
  emits: ['show-slide-indexes'],
  computed: {
    showInDom (): number {
      return this.$store.getters[UiGetters.getSlidesInDom]
    }
  },
  methods: {
    async activePageChange () {
      await new Promise(resolve => setTimeout(resolve, 800))
      // @ts-ignore
      if (!this.enableGtmTracking || !this.$refs.vekecarousel || !this.$refs.vekecarousel.slideCount) {
        return
      }
      // @ts-ignore
      const slides = this.$refs.vekecarousel.slideCount
      // @ts-ignore
      const currentPage = this.$refs.vekecarousel.currentPage
      // @ts-ignore
      const slideIndexes = [...Array(this.$refs.vekecarousel.slideCount).keys()]
      // @ts-ignore
      const totalPages = Math.round(slides / this.showInDom) - 1
      let slideHelper: any
      if (totalPages === currentPage) {
        // @ts-ignore
        slideHelper = slideIndexes.slice(-this.showInDom)
      } else {
        // @ts-ignore
        const startFromIndex = currentPage * this.showInDom
        // @ts-ignore
        slideHelper = slideIndexes.splice(startFromIndex, this.showInDom)
      }
      // @ts-ignore
      this.$emit('show-slide-indexes', { algoliaFirstIndex: slideHelper[0], itemAmount: this.showInDom })
    },
    handleNavigation (direction: string) {
      if (this.$refs.vekecarousel) {
        // @ts-ignore
        this.$refs.vekecarousel.handleNavigation(direction)
      }
    }
  }
})
