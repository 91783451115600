import { render, staticRenderFns } from "./index.vue?vue&type=template&id=c88ca280"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageMessages: require('/src/nuxt-app/components/page-message/PageMessages.vue').default,Banner: require('/src/nuxt-app/components/Banner.vue').default,VideobotSlider: require('/src/nuxt-app/components/videobot/VideobotSlider.vue').default,HeadingLink: require('/src/nuxt-app/components/HeadingLink.vue').default,CampaignTileBanners: require('/src/nuxt-app/components/campaign/CampaignTileBanners.vue').default,ProductTileCarousel: require('/src/nuxt-app/components/product/tile-carousel/ProductTileCarousel.vue').default,BlogHighlights: require('/src/nuxt-app/components/blog/BlogHighlights.vue').default})
