import { ref } from '@nuxtjs/composition-api'

export function useIntersection (options = {}, runOnce = true) {
  const defaultOptions = { threshold: 0.5 }
  const opts = { ...defaultOptions, ...options }
  const checkIntersection = (entries: IntersectionObserverEntry[], observer: any) => {
    entries.forEach((entry) => {
      isIntersecting.value = entry.isIntersecting
      if (runOnce && entry.isIntersecting) {
        observer.unobserve(entry.target)
      }
    })
  }

  let observer: IntersectionObserver|null = null

  const isIntersecting = ref(false)

  // onMounted(() => {
  //   if (!element) {
  //     console.log('🔴 Could not found element to observe', element)
  //   } else {
  //     console.log('intersect')
  //     observer = new IntersectionObserver(checkIntersection, opts)
  //     observer.observe(element.value as Element)
  //   }
  // })

  const startIntersecting = (element: Element) => {
    observer = new IntersectionObserver(checkIntersection, opts)
    observer.observe(element as Element)
  }

  const stopIntersecting = (element: Element) => {
    observer = new IntersectionObserver(checkIntersection, opts)
    observer.unobserve(element)
  }

  // onUnmounted(() => {
  //   if (observer && element.value) {
  //     observer.unobserve(element.value)
  //   }
  // })

  return { isIntersecting, startIntersecting, stopIntersecting }
}
